import React, { Fragment } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import { SEO } from "../components/SEO";
import Footer from "../components/Footer";

const Index = ({ data: { sanityCategory, allSanityArticle }, params }) => {
  const slug = params.category;
  const { nodes } = allSanityArticle;
  return (
    <Fragment>
      <Layout>
        <section className={"bg-black"}>
          <div className={"container mx-auto py-10"}>
            <h1 className={"gg-text text-5xl font-lora min-h-[60px]"}>
              {sanityCategory.title}
            </h1>
            <p className={"font-lora max-w-2xl text-white mt-5"}>
              {sanityCategory.description}
            </p>
          </div>
        </section>

        <section className={"container mx-auto py-10"}>
          {nodes.map((article, i) => {
            return (
              // <Link to={`/articles/${sanityCategory.slug.current}/${article.slug.current}`} key={i}>
              <Link to={`/${article.slug.current}`} key={i}>
                <p
                  className={
                    "bg-[#f1f1f16e] rounded-[10px] font-lora py-5 px-10 mb-3"
                  }
                >
                  {article.heading}
                </p>
              </Link>
            );
          })}
        </section>
      </Layout>
      <Footer />
    </Fragment>
  );
};

export default Index;
export const Head = ({ data: { sanityCategory } }) => (
  <SEO metadata={sanityCategory?.pageMetadata || {}} />
);
export const query = graphql`
  query ($categoryId: String!) {
    sanityCategory(id: { eq: $categoryId }) {
      title
      slug {
        current
      }
      description
      id
      pageMetadata {
        title
        schema
        keywords
        description
        canonical
        image {
          asset {
            url
          }
        }
      }
    }
    allSanityArticle(
      filter: { category: { elemMatch: { id: { eq: $categoryId } } } }
      sort: { order: DESC, fields: _createdAt }
    ) {
      nodes {
        templateType
        slug {
          current
        }
        id
        heading
      }
    }
  }
`;
